/* general fonts */
@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans'), url(assets/fonts/WorkSans/WorkSans-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans-italic';
  src: local('WorkSans-italic'), url(assets/fonts/WorkSans/WorkSans-Italic-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans-Medium';
  src: local('WorkSans-Medium'), url(assets/fonts/WorkSans/WorkSans-Medium.ttf) format('truetype');
}


/* general colors */
:root {
  --taggie-black: #090302;
  --taggie-snow-white: #FDF7FA;
  --taggie-grey: #5F7681;
  --taggie-light-grey: #909497;
  --taggie-green: #48BC43;
  --taggie-red: #FF5A47;
  --taggie-light-red: #FFB3AA; /* added by Silas, not from the documentation. */
  --taggie-bright-pink: #EA1E61;
  --taggie-light-pink: #F58EB1;
  --taggie-bright-aqua: rgb(66, 240, 205);
  --taggie-light-aqua: #C6FAF0;
  --taggie-bright-yellow: #F7FD49;
  --taggie-light-yellow: #FEFFC2;
  --taggie-bright-mint: #6FFF47;
  --taggie-light-mint: #BFFFAD;
  --taggie-bright-rosemary: #7B47FF;
  --taggie-light-rosemary: #E2D6FF;
}

* {
  font-family: 'WorkSans', serif;
}

html {
  font-size: 14px;
}

body {
  background-color: #F2F2F2;
}

.italic {
  font-family: 'WorkSans', serif;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.btn-standard {
  width: 100%;
  border-radius: 15px;
  max-width: 250px;
  border: #5f7681 3px solid;
  color: #5f7681;
  display: inline-block;
  height: 45px;
  line-height: 41px;
  padding: 0 16px;
  text-transform: lowercase;
  vertical-align: middle;
  margin-bottom: 1rem;
  font-size: medium;
  font-family: WorkSans-Medium, serif;
  box-shadow: 0.9px 0.9px 1.4px rgba(0, 0, 0, 0.01) inset,
  1.5px 1.5px 2.8px rgba(0, 0, 0, 0.014) inset,
  1.9px 1.9px 4.4px rgba(0, 0, 0, 0.017) inset,
  2.2px 2.2px 6.6px rgba(0, 0, 0, 0.02) inset,
  2.4px 2.4px 10px rgba(0, 0, 0, 0.023) inset,
  2.8px 2.8px 16.4px rgba(0, 0, 0, 0.026) inset,
  3.3px 3.3px 30.7px rgba(0, 0, 0, 0.03) inset,
  5px 5px 80px rgba(0, 0, 0, 0.04) inset,
  3.7px 3.7px 1.6px rgba(0, 0, 0, 0.029),
  6px 6px 3.6px rgba(0, 0, 0, 0.005),
  7.6px 7.6px 6.3px rgba(0, 0, 0, 0.051),
  8.7px 8.7px 10px rgba(0, 0, 0, 0.06),
  9.8px 9.8px 15.5px rgba(0, 0, 0, 0.069),
  11.1px 11.1px 24.1px rgba(0, 0, 0, 0.078),
  13.4px 13.4px 40px rgba(0, 0, 0, 0.091),
  20px 20px 80px rgba(0, 0, 0, 0.12);
  text-shadow: 3.7px 3.7px 1.6px rgba(0, 0, 0, 0.029),
  6px 6px 3.6px rgba(0, 0, 0, 0.005),
  7.6px 7.6px 6.3px rgba(0, 0, 0, 0.051),
  8.7px 8.7px 10px rgba(0, 0, 0, 0.06),
  9.8px 9.8px 15.5px rgba(0, 0, 0, 0.069),
  11.1px 11.1px 24.1px rgba(0, 0, 0, 0.078),
  13.4px 13.4px 40px rgba(0, 0, 0, 0.091),
  20px 20px 80px rgba(0, 0, 0, 0.12);
}

.btn-standard:hover {
  background-color: var(--taggie-light-grey);
  box-shadow: none;
  text-shadow: none;
}


.btn-standard.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.text-tag {
  position: relative;
  width: 90%;
  height: 65vh;
  padding: 32px;
  border-radius: 15px;
  color: var(--taggie-black);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(var(--taggie-bright-aqua), var(--taggie-light-aqua));
  margin-bottom: 1rem;
}


.tile-space {
  width: 100%;
  margin: 1vw;
  text-align: center;
  font-family: WorkSans-Medium, serif;
  overflow-wrap: break-word;
}

.very-short-text {
  font-size: 20vw;
}

.short-text {
  font-size: 13vw;
}

.short-medium-text {
  font-size: 11vw;
}

.medium-text {
  font-size: 9vw;
}

.long-text {
  font-size: 7vw;
}

.very-long-text {
  font-size: min(6.5vw, 3em);
}


/*The way heading should appear is styled here.*/
/*Ideally use it with a heading h2 for the page heading and h5 for SubHeadings*/
.taggie-title {
  text-align: center;
  font-family: WorkSans-Medium, serif;
}







